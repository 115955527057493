import "normalize.css";
import "./ProducerDetailTemplate.scss";

import React, { FC, useContext, useEffect, useRef, useState } from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import NavContext, {
  NavProvider,
} from "../../components/Layout/Nav/NavProvider";

import HomeSliderContact from "../../components/HomeSliderContact";
import SectionHeader from "../../components/SectionHeader";
import Project from "../../components/Project";
import Slider from "../../components/Slider";
import { t } from "i18next";
import { graphql } from "gatsby";
import {
  getSectionSlug,
  PageTemplateProps,
  YouplanetSections,
} from "../../constants/general";
import { getCurrentLanguage } from "../../i18n";
import ArrowMoreContent from "../../components/ArrowMoreContent";
import { ProjectDetailContent } from "../../components/ProjectDetailContent";
import { useInView } from "../../hooks/useInView";
import { RelatedProjects } from '../../components/RelatedProjects';

const PageTemplate: FC<PageTemplateProps> = ({ data, pageContext }) => {
  const { project, producer, producerList } = data as any;

  const detailData = producer || project;
  const currentLanguage = getCurrentLanguage()
  const currentSlug = pageContext.slug;
  const producerListByLanguage = producerList.nodes.filter(x => x.slug.current.includes(`${currentLanguage}/`));

  const pageTitle = `${t("projectDetail.sectionName")} Youplanet - ${
    detailData.name
  }`;
  const description = `${t("projectDetail.sectionName")} Youplanet - ${
    detailData.name
  }`;
  const alternativeUrls = pageContext.alternativeUrls;

  const section = useRef<HTMLDivElement>();

  const producerDetailVideo = useRef<HTMLDivElement>();
  const [isVideoVisible, setIsVideoVisible] = useState(true);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);

  const { isMobile } = useContext(NavContext);
  const inView = useInView(section, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  useEffect(() => {
    setIsVideoVisible(inView);
  }, [inView])

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  const goBackLink = () => {
    return `${getSectionSlug(
      YouplanetSections.Producer,
      getCurrentLanguage()
    )}`;
  };

  const goBackArrowLink = () => {
    return (
      <div className="producer-detail__link">
        <a href={goBackLink()}>
          <ArrowMoreContent
            color="grey"
            containerHeight="2rem"
            containerWidth="16rem"
            sideWidth="1rem"
            width=".2rem"
            direction="left"
            clickHandler={null}
          />
        </a>
      </div>
    );
  };
  const projectVideo = () => {
    return (
      <div
        ref={producerDetailVideo}
        className={`project-detail__video is-active`}
      >
        {goBackArrowLink()}
        <Project
          title={detailData.name}
          videoId={detailData.youtubeId}
          titleTag="p"
          autoPlay
          isDetail
          customCursor
          isMobileAutoplayActive={isVideoVisible}
          enableFullScreen
        />
      </div>
    );
  };

  function getList() {
    let list = detailData?.relatedItems;

    if (detailData?.relatedItems.length <= 0) {
      list = producerListByLanguage.filter((n) => n.name != detailData.name);
    }
    return list;
  }

  function pageContent(usePrevSlideOption?: boolean) {
    const elements: JSX.Element[] = [
      projectVideo(),
      <ProjectDetailContent
        isMobilePrevSlideActive={usePrevSlideOption}
        project={detailData}
        goBackElement={goBackArrowLink()}
      />,
      <RelatedProjects list={getList()}  startsIn={detailData.position} />,
      <HomeSliderContact />,
    ];
    return elements.map((el, index) => <span key={`${index}-producerDetailTemplate`}>{el}</span>);
  }

  return (
    <NavProvider>
      <Layout
        language={currentLanguage}
        alternativeUrls={alternativeUrls}
        isHome={false}
      >
        <SEO
          title={pageTitle}
          description={description}
          lang={currentLanguage}
          slug={currentSlug}
        />
        <SectionHeader inView={true} />
        <section ref={section} className="producer-detail">
          {isMobileActive ? (
            pageContent(isVideoVisible)
          ) : (
            <Slider>{pageContent()}</Slider>
          )}
        </section>
      </Layout>
    </NavProvider>
  );
};

export default PageTemplate;

export const query = graphql`
  query ProducerDetailTemplateQuery($id: String!, $lang: String) {
    project: sanityProject(id: { eq: $id }) {
      name
      brand
      slug {
        current
      }
      type
      shortDescription
      department
      description: _rawDescription
      youtubeId
      mobileYoutubeId
      position
      relatedItems {
        name
        brand
        slug {
          current
        }
        type
        shortDescription
        defaultImage {
          ...ImageFragment
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    producer: sanityProducer(id: { eq: $id }) {
      name
      brand
      slug {
        current
      }
      type
      shortDescription
      department
      description: _rawDescription
      youtubeId
      position
      relatedItems {
        name
        brand
        slug {
          current
        }
        type
        shortDescription
        defaultImage {
          ...ImageFragment
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    producerList: allSanityProducer(
      sort: { order: ASC, fields: [position] }
      filter: { i18n_lang: { in: [$lang, null] }, _id: { regex: "/^(?!drafts.).*/" } }
    ) {
      nodes {
        name
        brand
        slug {
          current
        }
        type
        shortDescription
        defaultImage {
        ...ImageFragment
        }
      }
    }
  }
`;
